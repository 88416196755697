import { createSlice } from "@reduxjs/toolkit";
import { Foto, Quantidade } from "../produto";

interface Produto {
    id: number,
    title: string,
    categoria_id: number,
    status: string,
    foto: Foto | null,
    quantidade: number,
    quantidade_produto: Quantidade
}

interface Carrinho {
    itens: Produto[]
}

const carrinhoJson = localStorage.getItem('@universal:carrinho');
const initialState:Carrinho = carrinhoJson ? JSON.parse(carrinhoJson) : {
    itens: []
}

const carrinhoSlice = createSlice({
    name: "carrinho",
    initialState,
    reducers: {
        addItem: (state, action) => {
            const itens = [...state.itens]
            const id = action.payload.id
            const quantidade_produto_id = action.payload.quantidade_produto.id
            const index = itens.findIndex(item => item.id === id && item.quantidade_produto.id === quantidade_produto_id)
            
            if(index !== -1) {
                const item = itens[index];

                itens[index] = {
                    ...item,
                    quantidade: item.quantidade + 1
                };
            } else {
                itens.push(action.payload)
            }

            state.itens = itens
        },
        changeQuantidade(state, action) {
            const index:number = action.payload.index;
            const quantidade:number = action.payload.quantidade;
            
            if(quantidade < 1) {
                state.itens.splice(index, 1)
                return;
            }

            const itens = [...state.itens];

            itens[index] = {
                ...itens[index],
                quantidade
            };

            state.itens = itens;
        },
        removeItem: (state, action) => {
            state.itens.splice(action.payload.index, 1)
        },
        limpar: (state, action) => {
            state.itens = []
        }
    },
});

export default carrinhoSlice;
