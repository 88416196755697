import { createApp } from "vue";
import store from "./store";

const count = document.querySelector(".button-carrinho__count");
const price = document.querySelector(".button-carrinho__price");

if (count)
    count.innerHTML = "";

if (price)
    price.innerHTML = "";

const app = createApp({
    data() {
        return {
            carrinho: {
                itens: []
            },
        };
    },
    computed: {
        total() {
            let total = 0
            for (let item of this.carrinho.itens) {
                total += item.quantidade_produto.price * item.quantidade
            }

            return `Total: ${total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`;
        },
    },
    beforeMount() {
        store.subscribe(() => {
            this.carrinho = store.getState();
        });

        this.carrinho = store.getState();
    },
    methods: {
        open() {
            const carrinho = document.querySelector('.carrinho');
            carrinho?.classList.add('carrinho--open');
        }
    }
});

const carrinhoButton = document.querySelector('#carrinho__button');

if (carrinhoButton)
    app.mount(carrinhoButton)