const dropdown = document.querySelector(".nav__item--dropdown");
const navSublist = document.querySelector(".nav__sublist");

const isOpen = () => {
    return navSublist?.clientHeight === 300
}

dropdown?.addEventListener("mouseenter", () => {
    navSublist?.setAttribute("style", `height: 300px`);
});

dropdown?.addEventListener("mouseleave", () => {
    navSublist?.setAttribute("style", "height: 0px");
});

dropdown?.addEventListener("mousedown", (e) => {
    e.preventDefault();

    navSublist?.setAttribute("style", `height: ${isOpen() ? 0 : 300}px`);
});

document.body.addEventListener("mousedown", (e) => {
    const target = e.target as HTMLElement;

    if (navSublist?.className.match(target.className) || !navSublist) return;

    if (isOpen())
        navSublist?.setAttribute("style", `height: 0px`);
});
