const setup = {
    "password": {
        type: 'text',
        icon: 'fa-solid fa-eye'
    },
    "text": {
        type: 'password',
        icon: 'fa-solid fa-eye-slash'
    }
}
const passwords = document.querySelectorAll('.input__field[type="password"]');

passwords.forEach(password => {
    const button = password.nextElementSibling;
    const icon = button?.querySelector('.fa-solid');

    button?.addEventListener('click', () => {
        const currentType = password.getAttribute('type') as 'text' | 'password';

        password.setAttribute('type', setup[currentType].type);
        if (icon)
            icon.className = setup[currentType].icon;
    })
});