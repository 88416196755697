import { bindValidator } from "../validators";

const form = document.querySelector<HTMLFormElement>(".login__form");

if(form) {
    const validator = bindValidator(form);
    
    form?.addEventListener('submit', (e) => {
        if(!validator.validate())
            e.preventDefault();
    })
}