import { bindValidator } from './validators';
import store from './store';
import carrinhoSlice from "./slices/carrinhoSlice";
import Swal from 'sweetalert2';
import axios from 'axios';

const form = document.querySelector<HTMLFormElement>('.compra__form');
const action = document.querySelector('.compra__action');

if (form) {
    const validator = bindValidator(form);

    form.addEventListener('submit', async (e) => {
        e.preventDefault();

        const valid = validator.validate();

        if(!valid) return;

        await Swal.fire({
            title: "Você deseja confirmar a sua compra?",
            icon: "info",
            showCancelButton: true,
            confirmButtonText: "Sim, confirmar compra",
            cancelButtonText: "Não",
            cancelButtonColor: "#aaa",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    action?.classList.add('button--loading');
                    const usuarioId = document.querySelector<HTMLInputElement>('input[name="usuario_id"]');
                    const nome = document.querySelector<HTMLInputElement>('input[name="nome"]');
                    const telefone = document.querySelector<HTMLInputElement>('input[name="telefone"]');

                    const data = {
                        produtos: store.getState().itens
                    }

                    await axios.post("/api/pedidos", usuarioId ? {
                        ...data,
                        usuario_id: usuarioId.value
                    } : {
                        ...data,
                        nome: nome?.value,
                        telefone: telefone?.value
                    }, {
                        withCredentials: true
                    });

                    const { limpar } = carrinhoSlice.actions;

                    store.dispatch(limpar({}));
                    window.location.replace("/pedido-realizado");
                } catch (e) {
                    console.log(e);
                } finally {
                    action?.classList.remove('button--loading');
                }
            }
        });
    });

    action?.addEventListener('click', (e) => {
        const submit = new Event('submit', { cancelable: true });
        form.dispatchEvent(submit);
    })
}

const updateResumo = () => {
    const carrinho = store.getState();

    const quantidade = document.querySelector('.compra__quantidade');
    const total = document.querySelector('.compra__total');

    if (quantidade)
        quantidade.textContent = `Quantidade de itens: ${carrinho.itens.length}`

    let valor = 0;
    carrinho.itens.forEach(item => {
        valor += item.quantidade_produto.price
    });

    if (total)
        total.textContent = valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
}

updateResumo();
store.subscribe(updateResumo);