import { configureStore, createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import carrinhoSlice from "./slices/carrinhoSlice";

const listenerMiddleware = createListenerMiddleware()

const { addItem, removeItem, changeQuantidade, limpar } = carrinhoSlice.actions

listenerMiddleware.startListening({
    matcher: isAnyOf(
        addItem,
        removeItem,
        changeQuantidade,
        limpar
    ),
    effect: async (action, listenerApi) => {
        const carrinho = listenerApi.getState();
        
        localStorage.setItem('@universal:carrinho', JSON.stringify(carrinho));
    }
})

const store = configureStore({
    reducer: carrinhoSlice.reducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().prepend(listenerMiddleware.middleware),
});

export default store