import vanillaToast from 'vanilla-toast/vanilla-toast'

if(window.notification) {
    const notification = window.notification;

    vanillaToast.show(notification.message, {
        className: notification.className,
        duration: 3000,
        fadeDuration: 300
    });
}