import Splide from '@splidejs/splide';

document.addEventListener('DOMContentLoaded', function () {
    const bannersSplide = document.querySelector(".banners__splide");

    if (bannersSplide) {
        new Splide(".banners__splide", {
            type: 'loop',
            perPage: 1,
            pagination: false,
            arrows: false,
            autoplay: true,
            interval: 5000,
            rewind: true
        }).mount();
    }
});