const modals = document.querySelectorAll('.modal');

modals.forEach(modal => {
    const close = modal.querySelector('.modal__close');

    close?.addEventListener('click', (e) => {
        e.preventDefault();

        modal.classList.toggle('modal--open');
    })
})