const filtrar = document.querySelector(
    ".produtos__filtrar select"
) as HTMLSelectElement | null;

filtrar?.addEventListener("change", () => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("ordenar-por", filtrar.value);

    window.location.search = searchParams.toString();
});
