import Splide from '@splidejs/splide';

document.addEventListener('DOMContentLoaded', function () {
    const categorias = document.querySelector(".categorias__splide");

    if (categorias) {
        const splide = new Splide(".categorias__splide", {
            perPage: 7,
            gap: 40,
            padding: {
                left: 0,
                right: '20%'
            },
            breakpoints: {
                1279: {
                    perPage: 4
                },
                479: {
                    perPage: 2
                },
                374: {
                    perPage: 1
                }
            },
            classes: {
                arrows: 'splide__arrows categorias__arrows',
                arrow: 'splide__arrow categorias__arrow',
                prev: 'splide__arrow--left categorias__arrow--left',
                next: 'splide__arrow--right categorias__arrow--right',
            },
        });

        splide.on('mounted', () => {
            const index = splide.Components.Elements.slides.findIndex(element => element.classList.contains('categorias__slide--active'));

            if (index === -1) return;

            const item = splide.Components.Pagination.getAt(index);
            splide.Components.Controller.go(`>${item.page}`);
        });

        splide.mount();
    }
});