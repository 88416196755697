import Pristine from './pristine'

const isEmpty = (value: string | undefined) => {
    return value === '' || value === undefined
}

Pristine.setLocale('pt-br')
Pristine.addMessages('pt-br', {
    required: "Campo obrigatório",
    email: "O e-mail inserido é inválido",
    number: "Somente é aceito número",
    integer: "Somente é aceito números inteiros",
    url: "O campo deve conter um endereço válido",
    tel: "O campo deve conter um número de telefone válido",
    maxlength: "O campo deve ter um tamanho menor que ${1}",
    minlength: "O campo deve ter um tamanho maior que ${1}",
    min: "O valor mínimo é ${1}",
    max: "O valor máximo é ${1}",
    pattern: "O campo deve possuir esse formato",
    equals: "Os campos não coincidem"
})

export const bindValidator = (form: HTMLFormElement) => {
    const pristine = new Pristine(form, {
        classTo: 'form__field',
        errorClass: 'has-danger',
        successClass: 'has-success',
        errorTextParent: 'form__field',
        errorTextTag: 'span',
        errorTextClass: 'input__error'
    });

    return pristine;
}

Pristine.addValidator("required-without", function (value: string, field: string) {
    const element = document.querySelector<HTMLInputElement>(field);

    return isEmpty(element?.value) && !isEmpty(value);

}, "Campo obrigatório", 0, false);

Pristine.addValidator("telefone", function (value: string) {
    return isEmpty(value) || value.match(/^\([0-9]{2}\) [0-9]{4,5}-[0-9]{4,5}$/) !== null;

}, "Número inserido inválido", 0, false);

Pristine.addValidator("selected", function (value: string) {
    const id = parseInt(value);

    return Number.isInteger(id) && id > 0;

}, "Necessário selecionar", 0, false);

Pristine.addValidator("cep", function (value: string) {
    return isEmpty(value) || value.match(/^[0-9]{5}-[0-9]{3}$/) !== null;

}, "CEP inválido", 0, false);

Pristine.addValidator("cpf-cnpj", function (value: string) {
    if(isEmpty(value)) return true;

    const cpfCnpj = value.replace(/[^0-9]/g, '');

    return isEmpty(value) || cpfCnpj.length === 11 ? validateCpf(cpfCnpj) : validateCnpj(cpfCnpj);
}, "CPF/CNPJ inválido", 0, false);

const validateCpf = (value: string) => {
    let soma: number;
    let resto: number;
    soma = 0;
    if (value === "00000000000") return false;

    for (let i = 1; i <= 9; i++) soma = soma + parseInt(value.substring(i - 1, i)) * (11 - i);
    resto = (soma * 10) % 11;

    if ((resto === 10) || (resto === 11)) resto = 0;
    if (resto !== parseInt(value.substring(9, 10))) return false;

    soma = 0;
    for (let i = 1; i <= 10; i++) soma = soma + parseInt(value.substring(i - 1, i)) * (12 - i);
    resto = (soma * 10) % 11;

    if ((resto === 10) || (resto === 11)) resto = 0;
    if (resto !== parseInt(value.substring(10, 11))) return false;

    return true;
}

const validateCnpj = (value: string) => {
    if (value === '') return false;

    if (value.length !== 14)
        return false;

    if (value === "00000000000000" ||
        value === "11111111111111" ||
        value === "22222222222222" ||
        value === "33333333333333" ||
        value === "44444444444444" ||
        value === "55555555555555" ||
        value === "66666666666666" ||
        value === "77777777777777" ||
        value === "88888888888888" ||
        value === "99999999999999")
        return false;

    let tamanho = value.length - 2
    let numeros = value.substring(0, tamanho);
    let digitos = value.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2)
            pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== parseInt(digitos.charAt(0)))
        return false;

    tamanho = tamanho + 1;
    numeros = value.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== parseInt(digitos.charAt(1)))
        return false;

    return true;
}