import { createApp } from "vue";
import carrinhoSlice from "./slices/carrinhoSlice";
import store from "./store";
import { debounce } from 'lodash'

const app = createApp({
    data() {
        return {
            carrinho: {
                itens: []
            },
        };
    },
    computed: {
        total() {
            let total = 0
            for (let item of this.carrinho.itens) {
                total += item.quantidade_produto.price * item.quantidade
            }

            return `Total: ${total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}`;
        },
    },
    beforeMount() {
        store.subscribe(() => {
            this.carrinho = store.getState();
        });

        this.carrinho = store.getState();
    },
    methods: {
        close() {
            const carrinho = document.querySelector('.carrinho');
            carrinho?.classList.remove('carrinho--open');
        },
        formatPrice(price: number) {
            return price.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
        },
        changeQuantidade(index: number, quantidade: number) {
            const { changeQuantidade } = carrinhoSlice.actions;

            store.dispatch(changeQuantidade({ index, quantidade }));
        },
        removeItem(index: number) {
            const { removeItem } = carrinhoSlice.actions;

            store.dispatch(removeItem({ index }));
        },
        inputQuantidade: debounce(function (e: Event, i: number) {
            const element = e.target as HTMLInputElement;

            this.changeQuantidade(i, parseInt(element.value));
        }, 300)
    }
});

const carrinho = document.querySelector('#carrinho');

if (carrinho)
    app.mount(carrinho)