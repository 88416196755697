import axios from "axios";

const cep = document.querySelector<HTMLInputElement>(".cep");

interface Endereco {
    cep: string;
    logradouro: string;
    complemento: string;
    bairro: string;
    localidade: string;
    uf: string;
    ibge: string;
    gia: string;
    ddd: string;
    siafi: string;
    estado_id: number;
    cidade_id: number;
}

let timeout = 0;

cep?.addEventListener('input', (e) => {
    clearTimeout(timeout);

    const value = cep.value.replace(/[^0-9]/, '');
    if (value.length < 8)
        return;

    timeout = setTimeout(async () => {
        try {
            const response = await axios.get<Endereco>(`/api/ceps/${value}/endereco`);

            const endereco = response.data;
            const logradouro = document.querySelector<HTMLInputElement>('input[name="logradouro"]');
            const bairro = document.querySelector<HTMLInputElement>('input[name="bairro"]');
            const estado_id = document.querySelector<HTMLSelectElement>('select[name="estado_id"]');
            const cidade_id = document.querySelector<HTMLSelectElement>('select[name="cidade_id"]');

            if (logradouro)
                logradouro.value = endereco.logradouro

            if (bairro)
                bairro.value = endereco.bairro

            if (cidade_id) 
                cidade_id.setAttribute('data-id', endereco.cidade_id.toString())

            if (estado_id) {
                estado_id.value = endereco.estado_id.toString()
                const event = new Event('change');
                estado_id.dispatchEvent(event);
            }

        } catch (e) {
            console.log(e);
        }
    }, 400);
})
