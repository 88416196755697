import VMasker from 'vanilla-masker';

function inputHandler(masks: string[], max: number, event: Event) {
    const c = event.target as HTMLInputElement;
    const v = c.value.replace(/\D/g, '');
    const m = c.value.length > max ? 1 : 0;
    VMasker(c).unMask();
    VMasker(c).maskPattern(masks[m]);

    c.value = VMasker.toPattern(v, masks[m]);

    if (!event.cancelable)
        c.dispatchEvent(new Event('input', { cancelable: true }));
}

const telMask = ['(99) 9999-99999', '(99) 99999-9999'];
const telefones = document.querySelectorAll<HTMLElement>('.telefone');
telefones.forEach(telefone => {
    VMasker(telefone).maskPattern(telMask[0]);
    telefone.addEventListener('input', inputHandler.bind(undefined, telMask, 14), false);
})

const docMask = ['999.999.999-999', '99.999.999/9999-99'];
const docs = document.querySelectorAll<HTMLElement>('.documento');
docs.forEach(doc => {
    VMasker(doc).maskPattern(docMask[0]);
    doc.addEventListener('input', inputHandler.bind(undefined, docMask, 14), false);
});

const cep = document.querySelector('.cep');

if (cep)
    VMasker(cep).maskPattern("99999-999");