import Splide from '@splidejs/splide';

document.addEventListener('DOMContentLoaded', function () {
    const fotos = document.querySelectorAll<HTMLElement>(".fotos");

    if (fotos) {
        fotos.forEach(foto => {
            new Splide(foto, {
                type: 'slide',
                perPage: 1,
                arrows: false,
                rewind: true,
                classes: {
                    pagination: 'splide__pagination fotos__navs',
                    page: 'splide__pagination__page fotos__nav',
                },
            }).mount();
        })
    }
});