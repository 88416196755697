const pedidosItem = document.querySelectorAll('.pedidos__item') as NodeListOf<HTMLElement>;

const toggleHeight = (element: HTMLElement, height: number, init = false) => {
    const target = element.querySelector('.pedido__compra');
    const isShow = element.classList.contains('pedidos__item--show');

    target?.setAttribute(
        'style',
        `max-height: none; height: ${(init ? !isShow : isShow) ? 0 : height}px`
    )

    element.classList.toggle('pedidos__item--show', init ? isShow : undefined);
}

pedidosItem.forEach(pedidoItem => {
    const pedido = pedidoItem.querySelector('.pedido');
    const compraList = pedidoItem.querySelector('.compra__list');
    const height = Math.min(350, (compraList?.scrollHeight || 0) + 10);

    toggleHeight(pedidoItem, height, true);

    pedido?.addEventListener('click', (e) => {
        e.preventDefault();

        toggleHeight(pedidoItem, height);
    })
})