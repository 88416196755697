import axios from "axios";

interface Cidade {
    id: number;
    nome: string;
}

const estado_id = document.querySelector<HTMLSelectElement>('select[name="estado_id"]');
const cidade_id = document.querySelector<HTMLSelectElement>('select[name="cidade_id"]');

estado_id?.addEventListener('change', async () => {
    const id = estado_id.value;

    if (!cidade_id) return;
    
    try {
        while (cidade_id.hasChildNodes()) {
            const child = cidade_id.firstChild;

            if (child)
                cidade_id.removeChild(child);
        }

        const option = document.createElement("option");
        option.value = '0';
        option.textContent = "Carregando...";
        cidade_id.append(option);

        const response = await axios.get<Cidade[]>(`/api/estados/${id}/cidades`);

        const cidades = response.data;
        
        option.textContent = "Selecionar";

        cidades.forEach(cidade => {
            const option = document.createElement("option");
            option.value = cidade.id.toString();
            option.textContent = cidade.nome

            cidade_id.appendChild(option);
        });

        const cidadeId = cidade_id.getAttribute('data-id');

        if(cidadeId) {
            cidade_id.value = cidadeId;
            cidade_id.removeAttribute('data-id');
        }
    } catch (e) {
        console.log(e);
    }
});