import store from "./store";
import carrinho from "./slices/carrinhoSlice";
import { delay } from "lodash";
import { createApp } from "vue";

export interface Foto {
    id: number;
    path: string;
    thumb_path: string;
    url: string;
    thumb_url: string;
}

export interface Quantidade {
    id: number;
    prev_price: number | null;
    price: number;
    quantidade: number;
    quantidade_tipo: number;
    label: string;
}

interface Produto {
    id: number;
    title: string;
    status_id: number;
    categoria_id: number;
    quantidades: Quantidade[];
    fotos: Foto[];
}

const produtos = document.querySelector(".produtos__list");
const { addItem } = carrinho.actions;

const adicionadoListener = function (this: HTMLButtonElement) {
    delay(() => {
        this.classList.remove("produto__action--adicionado");

        this.removeEventListener("transitionend", adicionadoListener);
    }, 1000);
};

const modalApp = createApp({
    data() {
        return {
            quantidade_produtos: [],
            quantidade_produto_id: 0,
            quantidade: 1,
            callback: () => { },
        };
    },
    methods: {
        setQuantidadeProduto(quantidadeProdutos: Quantidade[]) {
            this.quantidade_produtos = quantidadeProdutos;
        },
        setCallback(callback: () => {}) {
            this.callback = callback;
        },
    },
}).mount(".home__modal");

produtos?.addEventListener("click", async (e: Event) => {
    const element = e.target as HTMLElement;

    if (!element.classList.contains("produto__action")) return;

    element.classList.remove("produto__action--adicionado");
    const modal = document.querySelector(".modal");
    const dataProduto = element.parentElement?.getAttribute("data-produto");
    const produto = JSON.parse(dataProduto as any) as Produto;

    await new Promise((resolve, reject) => {
        modal?.classList.toggle("modal--open");
        (modalApp.$data as any).quantidade = 1;
        (modalApp.$data as any).quantidade_produtos = produto.quantidades;
        (modalApp.$data as any).callback = () => {
            store.dispatch(
                addItem({
                    id: produto.id,
                    title: produto.title,
                    categoria_id: produto.categoria_id,
                    quantidade: (modalApp.$data as any).quantidade,
                    foto: produto.fotos[0] || null,
                    quantidade_produto: produto.quantidades[(modalApp.$data as any).quantidade_produto_id],
                })
            );
            modal?.classList.toggle("modal--open");
            resolve(true);
        };
    });

    element.addEventListener("transitionend", adicionadoListener);
    element.classList.add("produto__action--adicionado");
});
